const radConfig = {
  allottement_status: false,
  currentUrl: "https://clinix.aikenist.com/",
  baseUrl: "https://clinix.aikenist.com/api/quickrad",
  baseUrlRadio: "https://clinix.aikenist.com/api/quickrad/radio",
  radTitle: "AIkenist Technology Pvt. Ltd.",
  viewerURL: "https://clinix.aikenist.com/aikenviewer/viewer/",
  viewer3DURL:"https://clinix.aikenist.com/3daikenviewer/viewer?StudyInstanceUIDs=",
  viewerPET:"https://clinix.aikenist.com/3daikenviewer/tmtv?StudyInstanceUIDs=",
  viewerSM:"https://clinix.aikenist.com/3daikenviewer/microscopy?StudyInstanceUIDs=",  
  viewerMultiple: "https://clinix.aikenist.com/3daikenviewer/viewer?",
  medDream:"https://clinix.aikenist.com/?study=",
  thumbnailURL: "https://clinix.aikenist.com/dicom-web/instances/",
  gifURL:
  "https://clinix.aikenist.com/api/quickrad/general/convert-to-gif?series_ins=",
  dicomWeb: "https://clinix.aikenist.com/dicom-web",
  weasisDicomWeb: "https://clinix.aikenist.com/dicom-web",
  studyList: "https://clinix.aikenist.com/dicom-web/studies",
  localViewer: "https://clinix.aikenist.com/aikenviewer/local",
  client: "clinix",
  paymentComplete: true,
  version: "v2.0",
  radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
};

// ******************************************

localStorage.setItem("client", radConfig.client);

if (
  localStorage.getItem("showTAT") == "" ||
  localStorage.getItem("showTAT") == null
) {
  localStorage.setItem("showTAT", "false");
}
if (
  localStorage.getItem("studyTracker") == "" ||
  localStorage.getItem("studyTracker") == null
) {
  localStorage.setItem("studyTracker", "false");
}

export default radConfig;
